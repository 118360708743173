import React, { useEffect, useState, useContext, useRef } from 'react'
import querystringify from "querystringify"
import DynamicForm from '../../../components/FormBuilder/DynamicForm'
import HealthCategoryService from "../HealthCategoryService"
import { CityState } from "../../../components/Common/CityState"
import FormContainer from '../../../components/FormBuilder/FormContainer'
import GeoLocation from "../../../components/Common/GeoLocation"
import { CountryCodes } from "../../../constants/CountryCodes"
import useModal from "../../../hooks/useModal"
import GenericModal from "../../../components/Modals/GenericModal"
import NutritionCareNotification from '../../NutritionCares/NutritionCareNotification'
import { JourneyContext } from '../List/JourneyContext'
import CheckAccess from '../../Roles/CheckAccess'
import { useCurrentUserHook } from '../../Authentication/useUserHook'
import { NotificationPopup } from "../../Common/NotificationPopup"
//import {useFormTestReview, FormTestInputReviewFormPopup} from "../../FormTestInput/Reviews/FormTestInputReviewFormPopup"

function HealthJourneyForm(props) {
  
  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)

  const {currentUser} = useCurrentUserHook()
  const { journeyDispatch } = useContext(JourneyContext);
  
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})
  const {current:scope} = useRef({
    'formMode': null, 
    'profile':{}, 
    'data':{}, 
    'formFn':{} 
  })

  const { isOpen:isNutritionCareOpen, toggleModal:toggleNutritionCareModal } = useModal()
  //const {checkTestInputReviewNeeded, isTestInputReviewFormOpen, toggleTestInputReviewForm} = useFormTestReview()

  const currentOrgData = currentUser.current_organization?.data || {}

  const getById = (id) => {
    HealthCategoryService.get(id).then((res) => {
      if(res.status === 200){
        scope.journey = {...res.data.journey_profile, ...scope.journey.geo_data}
        scope.data = scope.journey.data || {}
        scope.formFn.editPermission = CheckAccess.hasMcjActivityEditAccess(
          currentUser, 
          scope.journey, 
          'journey_profile'
        );
        setFormRendered(true)
      }
    }).catch(function(res){
        scope.journey = null
    })
  }
  
  useEffect(() => { 
    scope.formMode = 'create-form-submissions'
    scope.journey = {} 
    scope.data = {
      'country': currentOrgData.country,
      'country_code': currentOrgData.country_code,
      'district': currentOrgData.district,
      'district_code': currentOrgData.district_code,
      'state': currentOrgData.state,
      'state_code': currentOrgData.state_code,
      'taluka': currentOrgData.taluka,
      'taluka_code': currentOrgData.taluka_code
    } 
    
    const f = currentOrgData.country_code?
      CountryCodes[currentOrgData.country_code.toLowerCase()]:null
    
    scope.formFn = {
      'is_public': queryParam.is_public?true:false,
      'form_type': 'journey_profile',
      'phone_display_format': f?.[4],
      'country_code': f?.[2],
    }

    if(params.id){
      scope.formMode = 'edit-form-submissions'
      getById(params.id)
    }else{
      setCaseId()
      setFormRendered(true)
    }
  }, [])

  const setCaseId = () =>{
    scope.data.case_id = ''
    if(currentUser.current_organization?.record_id)
      scope.data.case_id += (currentUser.current_organization?.record_id + '/')

    scope.data.case_id += (currentUser.record_id + '/')
    scope.data.case_id +=  new Date().getTime()
  }

  const create = (journey) => {
    journey.organization_id = currentUser.current_organization_id
    journey.medical_facility_id = currentUser.current_medical_facility_id
    journey.sub_facility_id = currentUser.current_sub_facility_id
    journey.created_by = currentUser.id
    journey.updated_by = currentUser.id
    journey.form_id = params.form_id
    journey.journey_type = queryParam.journey_type
    journey.data.health_condition = queryParam.type
    journey.member_role_id = currentUser.current_member?.role_record_id
    journey.is_test_data = currentUser.current_member?.permissions?.is_test_user
    journey.data_collected_by = queryParam.data_collected_by
    HealthCategoryService.create(journey).then((res) => {
      if(res.status === 201){
        if(queryParam.is_public){
          window.reload();
        }else{
          scope.journey = res.data.journey_profile;
          if(scope.journey.show_nutrition_care){
            scope.formFn.setLoading(false);
            toggleNutritionCareModal();
          }else{
            onSubmitPageRedirect();
            //checkTestInputReviewNeeded(scope.journey, onSubmitPageRedirect)
          }
        }
      }
    }).catch(function(res){
      back(); 
    })
  }

  const update = (journey) => {
    HealthCategoryService.update(journey).then((res) => {
      if(res.status === 204){
        scope.journey = journey;
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }
  
  const onSubmitPageRedirect = () => {
    journeyDispatch({'type': "CLEAR"})
    if(queryParam.go_back){
      back();
    }else if(queryParam.data_collected_by){
      props.history.push('/data/entry/members');
    }else{
      props.history.push('/health/table');  
    }
  }

  const back = () => {
    props.history.goBack();
  }

  const closeNutritionCare = (e) =>{
    toggleNutritionCareModal(e);
    onSubmitPageRedirect();
    //checkTestInputReviewNeeded(scope.journey, onSubmitPageRedirect);
  }

  if(!currentUser?.id) return null;

  if(!currentUser.current_medical_facility_id) {
    return(
      <div className="m-t-100">
        <NotificationPopup 
          title="Sorry, Please select medical facility." 
          iconClass="fas fa-circle-info lred"/>
      </div>
    )
  }

  /*if(isTestInputReviewFormOpen){
    return <FormTestInputReviewFormPopup 
      isOpen={isTestInputReviewFormOpen} 
      toggleModal={toggleTestInputReviewForm} 
      testInputId={scope.journey?.test_input?.id} 
      onclose={onSubmitPageRedirect}/>
  }*/

  if(isFormRendered){
    return (
      <>
        <FormContainer post={post}>
          <DynamicForm formMode={scope.formMode} formId={params.form_id} 
            form={scope.journey} data={scope.data} formFn={scope.formFn} 
            onCreate={create} onUpdate={update} 
            post={post} onCancel={back} setPost={setPost} 
            onSubmitRedirect={onSubmitPageRedirect}/>
          <CityState data={scope.data} formFn={scope.formFn} organizationId={currentUser.current_organization_id}/>
          <GeoLocation geoData={scope.journey}/>
        </FormContainer>

        {isNutritionCareOpen && 
          <GenericModal component={NutritionCareNotification} 
            toggleModal={closeNutritionCare} 
            isOpen={isNutritionCareOpen}
            itemType="journey_profile" 
            itemUuid={scope.journey.id}/>
        }
      </> 
    )  
  }

  return null;
  
}

export default HealthJourneyForm;