import BaseService from "../../services/BaseService";
import env from '../../env';
import axios from 'axios';

class TagMappingService extends BaseService {
	
	async filter(req){
	    return await axios.post(`${env.health_backend}tag_mapping/filter`, req);
	}

};

export default new TagMappingService('health_backend', 'tag_mappings');