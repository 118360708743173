import React, { useEffect, useState, useMemo } from "react";
import { PortalToggleMenu } from "../GenericPortalPage/Common";
import { HighRiskPregnancy } from "./Common";
import Cs from "../../../services/CommonService";
import { Link } from "react-router-dom";
import { DraftBadge } from "../../Common/NotificationPill";
import { UserName } from '../../UserProfile/UserInfoPopup';
import { useSubscriptionAccess } from '../../Subscriptions/useSubscription';
import { FormFieldCard } from "../../Common/FormFieldsView";
import { usePageLocale } from "../../../hooks/useLocale";
import ActivityService from "../MotherInfantJourney/Activities/ActivityService";
import FormTemplateService from "../../../services/FormTemplateService";
import { useCurrentUserHook } from "../../Authentication/useUserHook";
import { LinkFontAwesomeIcon } from "../../Common/ImageIcons";
import {FontAwesomeLink} from "../../Common/Button";
import moment from '../../../../node_modules/moment/min/moment.min.js';

const JourneyBlock = (props) => {
  let components = {
    'ckd_journey':CancerJourneyBlock,
    'cancer_journey':CancerJourneyBlock,
    'infant_journey':MotherInfantJourneyBlock,
  }

  const TagName = components[props.journeyType];  

  if(TagName){
    return <TagName {...props}/>
  }

  return <DefaultBlock {...props}/>   
}

const DefaultBlock = (props) => {
  const [loading, setLoading] = useState(true);
  const [formFields, setFormFields] = useState([]);
  let {journey, menuOptions, dataSource} = props;
  let {data, data_source} = journey;
  
  useEffect(()=>{
    getFormFields();
  }, [])

  let getFormFields = () => { 
    FormTemplateService.getFormFields({
      'ids':[dataSource.child_form], 
    }).then((res)=>{
      if(res.status === 200){
        setFormFields(res.data.form_fields[dataSource.child_form]);
        setLoading(false);
      }
    })
  }

  return (
    <div className="p10">
      <div className="flex justspacebetween aligncenter font-14">
        <span className="flex bold"> 
          <span>Last Updated</span>
          <span className="m-l-20">{Cs.formatUpdateDate(journey?.updated_at)}</span>
        </span>
        
        <Link data-tip="Edit"
          className="icon-gray-cirle brd-30 flex aligncenter justifycenter" 
          to={`/health/form/${journey.form_id}/${journey.id}`}>
            <i className="fas fa-edit white" />
        </Link>
      </div>

      <div className="mxh-250 m-t-5 scroll-auto">
        <div className="table card-table p-5">
          <FormFieldCard formFields={formFields} 
            data={data} dataSource={data_source}/>
        </div>  
      </div> 
    </div>
  ) 
}

const CancerJourneyBlock = (props) => {
  let {journey, menuOptions, orgBgImageStyle} = props;
  let {data, data_source} = journey;
  
  return (
    <>
      <div className="flex coldir fulwid p10">
        <div className="flex alignstart justspacebetween f16">
          <div>
            <div className="f24 m-r-20 text-blue-2 flex aligncenter">
              {journey.record_id} - {data.patient_name}
              <DraftBadge isDraft={journey.draft} styleName="m-l-15"/>
            </div>
            <ul className="flex aligncenter m-t-15 card-xs-content">
              <li className="m-r-20">
                <i className="fas fa-location-dot text-blue-2 m-r-5"/> 
                {journey.data.district}, {journey.data.state || "Address Not Provided"}
              </li>
              <li className="m-r-20">
                <i className="fas fa-phone text-blue-2 m-r-5"/> 
                {data.cell_number || "Mobile Not Provided"}
              </li>
              <li className="m-r-20">
                <span className="far fa-calendar-alt text-blue-2 m-r-5"/>
                {Cs.formatUpdateDate(journey.created_at, 'MMM D YYYY')}
              </li>
            </ul>
            <ul className="flex aligncenter m-t-15 card-xs-content">
              <li className="m-r-20">
                <span>Health Condition</span>
                {data_source.health_condition.label}
              </li>
            </ul>
            <ul className="flex aligncenter m-t-15 card-xs-content">
              <li className="m-r-20">
                <span>Last Update</span>
                {Cs.formatUpdateDate(journey.updated_at, 'MMM D YYYY')}
              </li>
            </ul>
          </div>  

          <div className="flex aligncenter">
            <Link data-tip="Edit"
              className="icon-gray-cirle brd-30 flex aligncenter justifycenter" 
              to={`/health/form/${journey.form_id}/${journey.id}`}>
                <i className="fas fa-edit" />
            </Link>

            <PortalToggleMenu journey={journey} menuOptions={menuOptions} 
              journeyType="infant_journey" menuDsId='3421'
              permissionKey="infant_portal"/>
          </div>  
        </div>
      </div>
    </> 
  )
}

const MotherInfantJourneyBlock = (props) => {
  const [motherWeight, setMotherWeight] = useState();
  const {subscriptionAccesses} = useSubscriptionAccess();
  const childForm = subscriptionAccesses.element_ppt_mcj.options.find(t => t.sid == "child_birth");

  const {currentUser} = useCurrentUserHook()
  const {labelLocale} = usePageLocale(currentUser.current_locale, 'motherTab');
  const {labelLocale:casePageLocale} = usePageLocale(currentUser.current_locale, 'casePortalPage');

  let {journey, child, menuOptions, orgBgImageStyle} = props;
  let {data} = journey;
  
  let qp = '';
  if(childForm?.child_form)qp += `form_id=${childForm?.child_form}`;
  if(journey.associated_journey_id)qp += `&associated_journey_id=${journey.associated_journey_id}`;
  
  const childFormLink = {
    pathname:`/infant/child/form/${journey.id}`,
    search:qp
  }

  const whData = (journey.form_id === 1174) 
    ? {
      "mother_height":journey.data?.mother_height,
      "mother_weight":journey.data?.mother_weight,
      "pregnancy_stage":journey.data_source.maternity_stage?.label
    } 
    : {
      "mother_height":journey.data_source?.mother_height?.label,
      "mother_weight":journey.data_source?.mother_weight?.label,
      "pregnancy_stage":journey.data_source.pregnancy_stage?.label
    }

  const mobileNo = (data.cell_number_formatted || data.cell_number);
  const showAddChildBtn = useMemo(()=>{
    const date = journey.data?.delivery_date_formatted_date || journey.data?.edd_formatted_date;
    if(date){
      //show 60 day before edd or delivery date
      return Cs.compareDate(
        moment(date).subtract(90, 'days'),
        null,
        'days',
        true
      ) > -1;
    }
    return true;
  }, [journey.id])

  useEffect(()=>{
    getLastPrenatalActivity();
  }, [])
  
  const getLastPrenatalActivity = async (fields) => {
    const {status, data} = await ActivityService.getById({
      'journey_profile_id': journey.id,
      'activity_type': 'prenatal_care',
      'fields':['last_activity']
    })
    if(status === 200){
      const activity = data.last_activity;
      setMotherWeight(
        activity?.data_source?.mother_weight?.label || activity?.data_source?.weight_mother?.label
      )
    }
  }

  return (
    <>
      <div className="flex coldir fulwid p10">
        <div className="flex alignstart justspacebetween f16">
          <div>
            <div className="f24 m-r-20 text-blue-2 flex aligncenter cgap-5">
              {journey.record_id} - {""+journey.data.mother_name || journey.data.patient_name}
              <DraftBadge isDraft={journey.draft} styleName="m-l-15"/>
              <HighRiskPregnancy isHighRisk={data.is_high_risk_pregnancy} />
            </div>
            <ul className="flex aligncenter m-t-15 card-xs-content">
              <li className="m-r-20">
                <i className="fas fa-location-dot text-blue-2 m-r-5"/> 
                <span>{journey.data.district}, {journey.data.state || "Address Not Provided"}</span>
                <span className="m-l-5">{journey.data.awc_name_number}</span>
              </li>
              <li className="m-r-20">
                <i className="fas fa-phone text-blue-2 m-r-5"/> 
                {mobileNo || "Mobile Not Provided"}
              </li>
              <li className="m-r-20">
                <span className="far fa-calendar-alt text-blue-2 m-r-5"/>
                {journey.data.record_date}
              </li>
            </ul>
            <ul className="flex m-t-15 card-xs-content">
              <li className="m-r-20 alignbaseline" label="Height">
                <div className="ff-1 f13 mgrey">{labelLocale(1)}</div>
                {whData.mother_height} cms 
              </li>
              <li className="m-r-20 alignbaseline" label="Weight">
                <div className="ff-1 f13 mgrey">{labelLocale(2)}</div>
                {whData.mother_weight} kgs
              </li>
              {data.edd && 
                <li className="m-r-20 alignbaseline" label="EDD">
                  <div className="ff-1 f13 mgrey">{labelLocale(7)}</div>
                  {data.edd}
                </li>
              }
              {motherWeight &&
                <li className="m-r-20 alignbaseline" label="Last Weight">
                  <div className="ff-1 f13 mgrey">Last W</div>
                  <div>
                    {motherWeight} kgs
                  </div>
                </li> 
              }
              {whData.pregnancy_stage &&
                <li className="m-r-20 alignbaseline">
                  <div className="ff-1 f13 mgrey">{casePageLocale(25)}</div>
                  <div>
                    {whData.pregnancy_stage}
                  </div>
                </li> 
              }
            </ul>
            <ul className="flex aligncenter m-t-15 card-xs-content">
              <li className="m-r-20 alignbaseline" label="Last Update">
                <div className="ff-1 f13 mgrey">{labelLocale(4)}</div>
                {Cs.formatUpdateDate(journey.updated_at, 'MMM D YYYY')}
              </li>
              <li className="m-r-20 alignbaseline" label="Name">
                <div className="ff-1 f13 mgrey">{labelLocale(5)}</div>
                <UserName id={journey?.updated_by} role={journey?.member_role}>{journey?.last_updated_user?.name}</UserName>
              </li>
              <li className="m-r-20 alignbaseline" label="HWC">
                <div className="ff-1 f13 mgrey">{labelLocale(6)}</div>
                <div>{journey?.member_role?.role}</div>
              </li>
            </ul>
          </div>  

          <div className="flex aligncenter">
            {!child && showAddChildBtn && 
              <FontAwesomeLink 
                css="lh-17"
                tooltip={casePageLocale(23)}
                link={childFormLink} 
                iconCss="fa fas fa-plus" 
                isResponsive={false} 
                name={casePageLocale(23)}>
              </FontAwesomeLink>
            }
            
            <LinkFontAwesomeIcon 
              tooltip={casePageLocale(24)}
              link={`/health/form/${journey.form_id}/${journey.id}`}
              iconCss="fas fa-edit white"/>

            <PortalToggleMenu journey={journey} menuOptions={menuOptions} 
              journeyType="infant_journey" menuDsId='3530'
              permissionKey="infant_portal" child={child}/>
          </div>  
        </div>
      </div>
    </> 
  )
  
}

export default JourneyBlock;