import React, { lazy, Suspense } from 'react';
import { Spinner } from '../Common/Spinner';
const PrivateRoute = lazy(() => import("../../routes/PrivateRoute"));
const SchedulesList = lazy(() => import('./List/PreScheduleEventList'));
const SchedulesForm = lazy(() => import('./Form/PreScheduleEventForm'));
const PreScheduleAlertForm = lazy(() => import('./Form/PreScheduleAlertForm'));

const PreScheduleEventRouter = (props) => (
  <Suspense fallback={<Spinner/>}>
    <PrivateRoute path="/schedule/event" component={SchedulesList} searchString={props.searchString} exact />
    <PrivateRoute path="/schedule/event/create/:type/:form_id/:id?" component={SchedulesForm} exact />
    <PrivateRoute path="/schedule/edit/:id" component={SchedulesForm} exact />
    <PrivateRoute path="/pre_schedule/alert/:pre_schedule_id/:id" component={PreScheduleAlertForm} exact />
  </Suspense>
)

export default PreScheduleEventRouter;