import React, { useState, useEffect } from 'react';
import FilterSlider from "../Common/FilterSlider";
import { CheckBox } from "../Common/FormInput";
import useModal from '../../hooks/useModal';
import { useToggle, ToggleIco } from '../../hooks/useToggle';
import SortDirection from '../Common/SortDirection';
import useSort from '../../hooks/useSorting';
import Cs from "../../services/CommonService";
import RoleService from "../Roles/RoleService";
import FormHelper from "../FormBuilder/FormHelpers";
import { useMedicalFacilityAutofillHook } from "../Organizations/MedicalCareFacility/useMedicalFacilityApi";
import { ClearFiltersBtn } from '../Common/Button';
import { FilterAccordionPanel, FilterPill, useFilterHook } from '../Common/AccordionPanel';
import { Spinner } from '../Common/Spinner';
import { SavedReportFilterList } from '../HealthJourney/MotherInfantJourney/Charts/SavedReportFilterList';

const Filters = ({ openSideNav, filter, setFilter, organizationId}) => {
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);

  const {getCount} = useFilterHook({filter});

  const {
    getFilters,
    getDepartmentPkId,
    dataSource, setDataSource,
  } = useMedicalFacilityAutofillHook({ filter, setLoading, organizationId })

  useEffect(() => {
    getFilters();
  }, [])

  useEffect(()=>{
    Cs.setSessionStore('testCaseListFilter', filter, true);
  }, [filter])

  if (!openSideNav) return null;

  const getRoles = async () => {
    if (!filter.department) return;
    const req = {
      'organization_id': organizationId,
      'department_dsl_id': getDepartmentPkId(filter.department)
    }
    const { status, data } = await RoleService.getRoleAutofill(req);
    if (status === 200) {
      setRoles(data);
    }
  }

  const toggleFilter = (value, name) => {
    if (value != null && value != filter[name]) {
      filter[name] = value;
      setFilter({ ...filter });
    } else {
      delete filter[name];
      setFilter({ ...filter });
    }
    if (name === 'department') {
      delete filter.talukas;
      delete filter.role_record_ids;

      setRoles([]);
      setFilter({ ...filter });
      getRoles();
    }
  }

  const filterExists = (value, name) => filter[name] == value;

  const toggleListFilter = (value, name) => {
    const setter = (list) => {
      if (list?.length === 0) {
        delete filter[name]
        setFilter({ ...filter });
      } else {
        setFilter({ ...filter, [name]: list });
      }
    }
    FormHelper.toggleListString(value, filter[name], setter);
  }

  const filterListExists = (value, name) => FormHelper.existsListString(value, filter[name]);

  return (
    <div className="filter-box-scroll p-5">
      {loading ? <Spinner/> : null}
        <div>
          <div className="bg-white m-b-10">
            <FilterAccordionPanel title="My saved filters">
              <div className="mxh-250 scroll-auto m-t-10">
                <SavedReportFilterList
                  filter={filter}
                  setFilter={setFilter}
                  insideAccordion={true}
                  filterableType="user_request"/>
              </div>
            </FilterAccordionPanel>
          </div>

          <div className="bg-white m-b-10">
            <FilterAccordionPanel
              title="Department"
              count={getCount('department')}
              isOpen={filter.department}>
                <div className="mxh-250 scroll-auto m-t-10">
                  <CheckBox list={dataSource.health_department_india?.options || []}
                    labelKey="label" valueKey="value"
                    toggle={toggleFilter} exists={filterExists}
                    name="department"/>
                </div>
            </FilterAccordionPanel>
          </div>

          <div className="bg-white m-b-10">
            <FilterAccordionPanel
              title="Roles"
              count={getCount('role_record_ids')}
              isOpen={filter.role_record_ids}>
                <div className="mxh-250 scroll-auto m-t-10">
                  <CheckBox list={roles}
                    labelKey="role" valueKey="record_id"
                    toggle={toggleListFilter} exists={filterListExists} name="role_record_ids" />
                </div>
            </FilterAccordionPanel>
          </div>
        </div>

        <div className="p-5 m-b-10">
          <ClearFiltersBtn onSelect={() => setFilter({}) }>
            Clear all filters
          </ClearFiltersBtn>
        </div>
    </div>
  )
}

const FilterBtn = (props) => {
  const { isOpen: isFilterOpen, toggleModal: toggleFilterModal } = useModal();
  const filterCount = Object.keys(props.filter).length;

  return (
    <>
      <span data-tip="Filter"
        className="bg-highlight round-btn float-right m-l-15"
        onClick={() => toggleFilterModal()}>
        <i className="fas fa-sliders-h" />
        {filterCount > 0 ? <span className="badge-2 bg-black-light-1 up">{filterCount}</span> : ''}
      </span>

      <FilterSlider component1={Filters}
        openSideNav={isFilterOpen}
        setOpenSideNav={toggleFilterModal}
        title=" Filters (Field Selection)"
        {...props} />
    </>
  )
}

const ActiveFilters = ({filter, setFilter}) => {

  const {getCount} = useFilterHook({filter});

  return (
    <div>
      <FilterPill filter={filter} setFilter={setFilter}
        label="Department"
        count={getCount('department')}
        filterKeys={['department']}/>
      <FilterPill filter={filter} setFilter={setFilter}
        label="Roles"
        count={getCount('role_record_ids')}
        filterKeys={['role_record_ids']}/>
    </div>
  )
}

export {Filters, FilterBtn, ActiveFilters};