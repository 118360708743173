import { useHistory } from "react-router-dom";

export default (state, action) => {

  switch (action.type) {
    case "LOGIN":
      const {record_id} = action.payload.user
      localStorage.cuedwell_uid = record_id
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case "LOGOUT":
      let history = useHistory();
      history.push('/signin');
      window.sessionStorage.clear();
      window.location.reload();
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    case "UPDATE":
      return {
        ...state,
        user: action.payload.user
      };
    case "UPDATE_SCREEN_VIEW_PORT":
      return {
        ...state, 
        screen: action.payload.screen
      };
    case "LOCK_IN_CURRENT_PAGE":
      return {
        ...state,
        redirectAllRouteToUrl:action.payload,
        user: {...state.user}
      };
    case "TOGGLE_PAGE_LOADER":
      return {
        ...state,
        showPageLoader:action.payload.showPageLoader
      };
    default:
      return state;
  }
};