import React from "react"
import InfantCard from "./Infant"
import Cancer from "./Cancer"
import Dementia from "./Dementia"
import AdultMetabolic from "./AdultMetabolic"
import NewVenture from "./NewVenture"

const JourneyCard = (props) => {
  let {journey} = props
  /*if(journey.form_id == 499){
    return <Cancer {...props}/>
  }else if(journey.form_id == 498){
    return <InfantCard {...props}/>
  }else if(journey.form_id == 500){
    return <InfantCard {...props}/>
  }*/

  if(journey.data_source?.health_condition?.sid == 'cancer_journey'){
    return <Cancer {...props}/>
  }else if(journey.data_source?.health_condition?.label == 'Dementia'){
    return <Dementia {...props}/>
  }else if(journey.journey_type == 'adult_metabolic'){
    return <AdultMetabolic {...props}/>
  }else if(journey.journey_type == 'nv_journey'){
    return <NewVenture {...props}/>
  }else{
    return <InfantCard {...props}/>
  }

  return null
}

export default JourneyCard;