import React, { useMemo, useContext } from "react";
import { Badge, DraftBadge } from "../../Common/NotificationPill";
import { OptionsObjList } from "../../Common/FormInput";
import Cs from "../../../services/CommonService";
import { PhoneIcon, MapIcon } from "../../Common/ImageIcons";
import { useActivityHook } from "../MotherInfantJourney/Activities/List/useActivityHook";
import { UserName } from '../../UserProfile/UserInfoPopup';
import { getLocale } from '../../FormBuilder/FieldLabel';
import { useSubscriptionAccess } from '../../Subscriptions/useSubscription';
import { LocalizeContext } from "../../../contexts/Localize/LocalizeStateProvider";
import useModal from "../../../hooks/useModal";
import { DataSourceMenu } from "../../Modals/DataSourceMenu";
import { MultiReviewScore } from "../../Reviews/Common";
import { CueTip } from "../../Common/CueTooltip";

const WeightZscorePill = ({j}) =>{
  const {getBgColorByZscore} = useActivityHook();
  const {last_activity:lastActivity} = j;
  const lastMeasurementData = lastActivity?.child_measurement?.data || {};
  const weightZscoreStyle = useMemo(() => 
    getBgColorByZscore(lastMeasurementData.weight_zscore, lastMeasurementData.weight_zscore_label), 
    [j.id, lastMeasurementData.weight_zscore, lastMeasurementData.weight_zscore_label]
  );
  
  let trendIco = lastMeasurementData?.weight_zscore_trend === 'low' 
  ? 'fas fa-arrow-down' 
  : lastMeasurementData?.weight_zscore_trend === 'high' 
  ? 'fas fa-arrow-up' 
  : '';

  return (
    <>
      {lastMeasurementData?.weight_zscore_trend || lastMeasurementData?.weight_zscore_label ? (
        <div className="badge-2 f14 bold-400 gap-5 flex aligncenter" style={weightZscoreStyle.r4}>
          {trendIco && <i className={trendIco} />}
          {lastMeasurementData?.weight_zscore_label && (
            <span>{lastMeasurementData.weight_zscore_label}</span>
          )}
        </div>
      ) : (
        lastMeasurementData?.weight_zscore && (
          <div
            className="circle-sm"
            style={{ background: weightZscoreStyle.r4.backgroundColor }}
          />
        )
      )}
    </>
  );
}

const HighRiskPregnancy = ({ isHighRisk }) => isHighRisk === 1 ?
  <span className="circle-sm bg-red-3 tooltip">
    <CueTip tooltip="High Risk Pregnancy" positionCss="left"/>
  </span>
  : null;

const RecordId = ({j, openFormSubmissionReview}) =>{
  const {record_id, draft} = j;
 
  return(
    <>
      <div>{record_id}</div>
      <DraftBadge isDraft={draft} styleName="d-block"/>
      <Badge show={j.is_test_data || j.test_input_id} className="bg-grey-3 d-block w-50">Test</Badge>
      <div className="iflex gap-10 aligncenter">
        <MultiReviewScore reviews={j.form_reviews} onSelect={openFormSubmissionReview}/>
      </div>
    </>
  )
}

const MCJDetails = ({j, routeJourney, labelLocale}) =>{
  let ds = j.data_source || {};
  let data = j.data || {};
  let child = j.child || {data:{}};
  const addressInfo = (data.taluka || data.address || '') +' '+ (data.village || '') +' '+ (data.awc_name_number || '');
  const mobileNo = (data.cell_number_formatted || data.cell_number);

  const {ageFormat} = useActivityHook(child);
  const babyAgeAgo = useMemo(() => ageFormat(null, Cs.getCurrentDateTime()), [j.id]);

  return(
    <>
      <div className="flex cgap-10 m-b-10 pointer" onClick={e=>routeJourney(j)}>
        <span className="flex cgap-10">
          <HighRiskPregnancy isHighRisk={data.is_high_risk_pregnancy} />
          {data.mother_name || data.patient_name}
        </span> 
        - 
        <span className="flex cgap-10">
          {child.data.baby_name ? ` ${child.data.baby_name}` : <BabyNameRequired labelLocale={labelLocale}/>}
          <WeightZscorePill j={j}/>
        </span>
        <span>{babyAgeAgo}</span>
      </div>
      <div className="flex cgap-10 coldir-xs">
        <div className="flex aligncenter m-r-10">
          <PhoneIcon title={mobileNo || <MobileRequired labelLocale={labelLocale}/>}/>
        </div>
        <div className="m-r-10">
          <div className="flex aligncenter">
            <MapIcon title={addressInfo.length > 0 ? addressInfo : <AddressRequired labelLocale={labelLocale}/>}/>
          </div>
        </div>
        <div className="flex aligncenter">
          <MultiplePregnancy child={j.child}/>
        </div>
      </div>
    </>
  )
}

const MCJDetails_2 = ({j}) =>{
  return(
    <>
      <div className="dblock m-b-10">{Cs.formatUpdateDate(j?.last_update_at)}</div>
      <UserName className="dblock" id={j.last_update_by} role={j.member_role}>
        {j?.last_updated_user?.name}
      </UserName>
      <UserName className="dblock" id={j.data_collected_by}>
        {j?.data_collected_user?.name}
      </UserName>
      <div className="dblock">{j?.member_role?.role}</div>
    </>
  )
}

const MobileRequired = ({labelLocale}) => <font className="c-bemedian">{labelLocale(13)}</font>;
const BabyNameRequired = ({labelLocale}) => <font className="c-bemedian">{labelLocale(12)}</font>;
const AddressRequired = ({labelLocale}) => <font className="c-bemedian">Address Not Provided</font>;
const StatuDropDown = ({id, status, statusReason, update, index, currentLocale}) => {
  const {localeState:{journeyStatus, statusCloseReasons, statusInactiveReasons}} = useContext(LocalizeContext)
  const { isOpen:isStatusReasonOpen, toggleModal:toggleStatusReason } = useModal()

  if(journeyStatus?.length > 0){
    const statusDs = journeyStatus?.find(o => o.sid === status);
    const statusStyle = status ? {
      'backgroundColor': statusDs?.bg_color,
      'color': statusDs?.color
    }:{}

    const onStatusUpdateSuccess = ({status}) =>{
      if(status === 'inactive' || status === 'closed'){
        toggleStatusReason()
      }
    }

    const getStatusReasonList = () => {
      if(status === 'inactive'){
        return statusInactiveReasons
      }else if(status === 'closed'){
        return statusCloseReasons
      }
    }

    const updateStatusReason = (reason) =>{
      update(
        index, 
        {
          'id':id, 
          'status_reason':{'label':reason.label, 'dsl_id':reason.pk_id}
        }, 
        ()=>toggleStatusReason()
      )
    }

    return(
      <div className="flex coldir">
        <select className="form-control input-sm fw500 wk-center no-border" style={statusStyle}
          onChange={(e)=>update(index, {'id':id, 'status':e.target.value, 'status_reason':null}, onStatusUpdateSuccess)} 
          placeholder="Status" defaultValue={status}>
            <OptionsObjList list={journeyStatus} idKey='sid' labelKey='label' userLocale={currentLocale}/>
        </select>
        <div>{statusReason?.label}</div>
        {isStatusReasonOpen ? 
          <DataSourceMenu toggleModal={toggleStatusReason} 
          datasourceList={getStatusReasonList()}
          onSelect={updateStatusReason}/>
          :
          null
        }
      </div>
    )
  }

  return <div className="text-cap">{status}</div>
}

//remove pregnancyStageKv
const Path = ({j, pregnancyStageKv={}, currentLocale}) =>{
  const {subscriptionAccesses:{pins_process_mcj, element_ppt_mcj}} = useSubscriptionAccess()

  const firstStage = useMemo(()=>{
    if(j.form_id == 1174){
      if(j.data_source.maternity_stage?.label == "Ante-natal Stage") {
        const pregnancyStage = j.data_source.pregnancy_stage?.label;
        const weekText = pregnancyStage ? "week " + pregnancyStage : "";
        return j.data_source.maternity_stage.label + " " + weekText;
      }else if(j.data_source.maternity_stage?.label == "Post-natal Stage"){
        return j.data_source.maternity_stage.label + " " + (j.data_source.pnc_stage?.label || "");
      }
    }else if(j.data_source?.pregnancy_stage){
      return getLocale(j.data_source.pregnancy_stage, currentLocale);
    }
    
    return null;
  }, [currentLocale]);

  /*Get label from PPT and PROCESS Tab for last_activity*/
  const lastStage = useMemo(()=>{
    let label = getLocale(pins_process_mcj?.options.find(o=>o.sid === j.last_activity_type), currentLocale)
    if(!label)
      label = getLocale(element_ppt_mcj?.options.find(o=>o.sid === j.last_activity_type), currentLocale)
    return label
  }, [currentLocale])

  if(firstStage){
    return(
      <div className="flex m-t-10 aligncenter">
        <span className="badge-3 ws-bs ws-unset w-30p f10-xss">
          {firstStage}
        </span>
        {j.last_activity_type ? 
          <>
            <i className="gg-arrow-long-right"/>
            <span className="badge-3 ws-bs ws-unset w-30p f10-xss">
              {lastStage}
            </span>
          </>
          : 
          null
        }
      </div>
    )
  }

  return null
}

const MultiplePregnancy = ({child}) =>{
  if(child?.data?.multiple_pregnancy > 1)
    return <span className="badge-2 bg-grey-4">{child.data_source?.multiple_pregnancy?.label}</span>

  return null
}

const AddMultyPregnancy = ({journeyChild, associatedJourney=[], onSelect}) =>{
  const noOfChildsInPregnancy = journeyChild?.data_source?.multiple_pregnancy?.value || 1
  
  if(noOfChildsInPregnancy > 1){
    return(
      <>
        {associatedJourney.map((j, i)=>
          <span key={i} className="badge-2 bg-lred m-r-10" onClick={()=>onSelect(j)}>Add Child {noOfChildsInPregnancy - i}</span>
        )}
      </>
    )
  }

  return null
}

const AdultMetabolicDetails = ({j, routeJourney, labelLocale}) =>{
  let ds = j.data_source || {};
  let data = j.data || {};
  const addressInfo = (data.district || '') +' '+ (data.state || '') +' '+ (data.country || '');

  return(
    <>
      <div className="m-b-10 pointer" onClick={()=>routeJourney(j)}>
        {data.name}
      </div>
      <div className="flex cgap-10 rgap-5 coldir-xs">
        <div className="flex aligncenter m-r-10">
          <PhoneIcon iconClass="far fa-calendar-alt" title={data.dob || <font className="c-bemedian">"DOB Required</font>}/>
        </div>
        <div className="flex aligncenter m-r-10">
          <MapIcon title={addressInfo.length>0 ? addressInfo : <AddressRequired labelLocale={labelLocale}/>}/>
        </div>
      </div>
    </>
  )
}

const AdultMetabolicDetails_2 = ({j}) =>{
  return(
    <>
      <div className="dblock m-b-10 m-t-5">{Cs.formatUpdateDate(j?.last_update_at)}</div>
      <UserName className="dblock" id={j.last_update_by} role={j.member_role}>
        {j?.last_updated_user?.name}
      </UserName>
      <UserName className="dblock" id={j.data_collected_by}>
        {j?.data_collected_user?.name}
      </UserName>
      <div className="dblock">{j?.member_role?.role}</div>
    </>
  )
}

export {HighRiskPregnancy, AdultMetabolicDetails, AdultMetabolicDetails_2, WeightZscorePill, AddMultyPregnancy, Path, RecordId, MCJDetails, MCJDetails_2, MobileRequired, BabyNameRequired, AddressRequired, StatuDropDown, MultiplePregnancy};