import React, { useState, useEffect, useContext, useMemo } from "react";
import GenericModal from '../Modals/GenericModal';
import AuthService from '../../services/AuthService';
import env from "../../env";
import { CountryCodes } from "../../constants/CountryCodes";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import Tabs, { TabPane } from "rc-tabs";
import CheckAccess from "../Roles/CheckAccess";
import { LocalizeContext } from "../../contexts/Localize/LocalizeStateProvider";
import { usePageLocale } from '../../hooks/useLocale';
import AddCaseAssessmentIco from "../HealthJourney/CaseAssessments/AddCaseAssessmentIco";
import { useCurrentUserHook } from "../Authentication/useUserHook";
import { CommentsBtn } from "../Modals/Comments";
import { Spinner } from "../Common/Spinner";
import Rating from "../Common/Rating";
import { UserInfoBtn } from "../Common/Button";
import useModal from "../../hooks/useModal";
import { DataSourceMenu } from "../Modals/DataSourceMenu";
import OrganizationMemberService from "../Organizations/Members/OrganizationMemberService";
import { MCJGroupByPregnancyStage, MCJCaseScheduleAdherence, CaseActivityCountTable, MeasureGrowthZscoreChartByCase, McjOrgMemberDailyReport, MCJCaseScheduleReport, MCJCaseTimeline } from '../Organizations/Members/ChartReports';

const UserInfoPopup = ({ userId, organizationId, role }) => {
  const {currentUser} = useCurrentUserHook();
  const { localeDispatch } = useContext(LocalizeContext);

  const orgId = organizationId || currentUser.current_organization_id;
  
  const [user, setUser] = useState({});
  const [orgMember, setOrgMember] = useState({});
  const [reports, setReports] = useState({});
  const [loading, setLoading] = useState(true);
  const [digitalTagOptions, setDigitalTagOptions] = useState({});

  const { labelLocale: tabLocale } = usePageLocale(currentUser.current_locale, 'userInfoTabs');
  const { labelLocale: labelLocale } = usePageLocale(currentUser.current_locale, 'userInfoLabels');

  const {isOpen:isDigitalTagOpen, toggleModal:toggleDigitalTag} = useModal();

  const isRoleTypePermitted = useMemo(() => CheckAccess.getRoleType(currentUser), [currentUser]);
  const digTagPermission = useMemo(() => CheckAccess.getMemberPermissionBySid(currentUser, 'org_user', 'dig_tag'), [currentUser.id]);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const { status, data } = await AuthService.getUserInfoById(
      userId,
      {
        'organization_id': orgId,
        'case_assessment_rating': true,
        'page_locale': {
          'data_source_ids': [4000, 4004, 4742],
          'locale': currentUser.current_locale,
        },
        'data_source_params': {
          'data_source_id': [4742],
          'locale': currentUser.current_locale,
        }
      }
    );
    let ext = CountryCodes[currentUser.current_organization.data?.country_code?.toLowerCase()];
    ext = ext ? ext[2] : '';
    setUser({
      ...data.user,
      'tel': `tel:+${ext}${data.user.phone_no}`,
      'mailto': `mailto:${data.user.email}`,
      'whatsapp': `https://wa.me/+${ext}${data.user.phone_no}?text=hi`,
      'assessment_rating': data.assessment_rating
    });

    if (data.org_member){
      const digitalTag = data.data_source_list?.digital_tag_case_work?.options?.find(d => d.sid == data.org_member.dig_tag_sid);
      setOrgMember({
        ...data.org_member,
        'dig_tag_label': digitalTag?.label
      });
    }

    setReports(data);
    setDigitalTagOptions(data.data_source_list?.digital_tag_case_work?.options);
    const pageLocale = data.page_locale;
    localeDispatch({
      'type': "UPDATE",
      'payload': {
        'userInfoTabs': (pageLocale?.user_info_tabs),
        'userInfoLabels': (pageLocale?.user_info_common_labels)
      }
    });
    setLoading(false);
  }

  const updateDigitalTag = async (ds) =>{
    const {status} = await OrganizationMemberService.update({
      'id':orgMember.id,
      'context':'open',
      'dig_tag_sid':ds.sid, 
      'dig_tag_dsl_id':ds.pk_id
    })
    if(status === 204){
      setOrgMember({
        ...orgMember,
        'dig_tag_sid':ds.sid, 
        'dig_tag_dsl_id':ds.pk_id,
        'dig_tag_label':ds.label
      })
      toggleDigitalTag();
    } 
  }

  return (
    <div className="p-t-40-xss">
      {loading ? <Spinner/> : null}
      <Tabs moreIcon="+">
        <TabPane tab={user.name || tabLocale(1)} key="0">
          <div className="flex justspacebetween aligncenter p-l-15 p-r-15">
            <div>
              <div className="flex justspacebetween aligncenter">
                {/* <h4>{user.name}</h4> */}
                {digTagPermission?.read ? <UserInfoBtn label={orgMember.dig_tag_label} onSelect={()=> toggleDigitalTag()}/> : null}
              </div>
              <div className="m-r-10"><Rating sumOfRating={user.assessment_rating}/></div>
              <div className="m-r-10">{role?.role}</div>
              <div>
                <p>
                  {orgMember.gender && `${orgMember.gender}/`}
                  {orgMember.age && `${orgMember.age} years/`}
                  {orgMember.education}
                </p>
              </div>
              <div className="flex font-24 m-t-4">
                {user.phone_no ? <a href={user.tel} className="fas fa-mobile-alt m-r-20"/> : null}
                {user.email ? <a href={user.mailto} className="fas fa-envelope m-r-20"/> : null}
                {user.phone_no ? <a href={user.whatsapp} target="_blank" className="fab fa-whatsapp-square m-r-20"/> : null}
                <AddCaseAssessmentIco className="highlight" assessUserId={userId} 
                  assessmentData={{ 
                    'assessment_type':'User', 
                    'assessment_type_sid':'user'
                  }}>
                    <span className="far fa-list-alt"/>
                </AddCaseAssessmentIco>
                <CommentsBtn isBtn={false} item={orgMember} currentUser={currentUser} 
                  commentableType="org_member" commentableId={orgMember.id}/>
                {/*currentUser.current_member
                  ? <span className="font-20 m-l-15">
                      <i className={`${currentUser.current_member.is_favorite ? 'fas green' : 'far'} fa-star`} onClick={() => updateFavorite()} />
                    </span>
                  : null
                */}
              </div>
            </div>
            <div>
              <span className="flex aligncenter m-r-30 m-r-22-xs user-dropdown-xs">
                <img className="user-md flex" src={user.profile_image ? env.file_url + user.profile_image : '../images/user-demo-icon.png'} />
              </span>
            </div>
          </div>
        </TabPane>

        {(isRoleTypePermitted || (userId === currentUser.id)) ?
          <>
            <TabPane tab={tabLocale(2)} key="1">
              {loading ? null : <MCJGroupByPregnancyStage userId={userId} organizationId={orgId} />}
            </TabPane>

            <TabPane tab={tabLocale(6)} key="2">
              {loading ? null : <MCJCaseScheduleReport userId={userId} organizationId={orgId} />}
            </TabPane>

            <TabPane tab={tabLocale(3)} key="3">
              {loading ? null : <MCJCaseScheduleAdherence userId={userId} organizationId={orgId} />}
            </TabPane>

            <TabPane tab="Timeline" key="7">
              {loading ? null : <MCJCaseTimeline userId={userId} organizationId={orgId} mcjComponents={reports.mcj_components}/>}
            </TabPane>

            <TabPane tab={tabLocale(4)} key="4">
              {loading ? null : <CaseActivityCountTable userId={userId} organizationId={orgId} mcjComponents={reports.mcj_components}/>}
            </TabPane>

            <TabPane tab={tabLocale(5)} key="5">
              {loading ? null : <MeasureGrowthZscoreChartByCase userId={userId} organizationId={orgId} />}
            </TabPane>

            <TabPane tab={tabLocale(7)} key="6">
              {loading ? null : <McjOrgMemberDailyReport userId={userId} organizationId={orgId} />}
            </TabPane>
          </>
          :
          null
        }
      </Tabs>
      <div className="flex aligncenter wrap justspacebetween">
        <small className="m-l-5 lgrey ws-nowrap ">
          <i className="m-r-5 far fa-copyright" aria-hidden="true" />
          {new Date().getFullYear()} IPIPAL Inc. All rights reserved.
        </small>
        <div className="text-gray m-r-10 ws-nowrap">
          {currentUser.current_organization.record_id} - {currentUser.record_id}
        </div>
      </div>
      {(isDigitalTagOpen && digTagPermission?.write) ? (
        <DataSourceMenu
          dataSourceId="4742"
          datasourceList={digitalTagOptions}
          title="Assigning status after case work"
          toggleModal={toggleDigitalTag}
          onSelect={updateDigitalTag}
          isSelected={o => orgMember?.dig_tag_sid?.includes(o.sid)}/>
      ) : null}
    </div>
  )
}

const UserName = ({id, orgId, className, role, children}) =>{
  const {state:{user:currentUser}} = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState();

  if(!id) return null;

  const userPopupEnabled = CheckAccess.checkOrgLevelAccess(currentUser, 'user_info_popup')

  if(userPopupEnabled){
    return(
      <>
        <div onClick={(e)=>{e.stopPropagation();setIsOpen(true)}} className={`uline-hov ${className}`}>
          {children}
        </div>
        {isOpen &&
          <GenericModal component={UserInfoPopup} toggleModal={()=>setIsOpen(false)} 
          userId={id} organizationId={orgId} role={role}/>
        }
      </>
    )
  }

  return <>{children}</>
  
}
export {UserName}