import React from "react"
import { PhoneIcon, MapIcon, LinkMapIcon, LinkMailIcon, LinkUserIcon, LinkDeleteIcon } from "../../Common/ImageIcons"
import Cs from '../../../services/CommonService'
import { StatuDropDown } from './Common'

const NewVenture = (props) => {
  let {journey, dataSources, currentUser, onDelete, openUserRole, routeJourney, openNotification, index, update, currentLocale} = props
  let {data, data_source:ds} = journey

  return(
    <div className="bg-white brd-10 card-shadow m-b-15">
      <div className="card-content p-15">
        <div className="scroll-auto ht-150">
          <div className="flex justspacebetween">
            <h4 className="fw700 f18 text-left">
              {journey.record_id} - {data.venture_name} 
            </h4>
            <StatuDropDown id={journey.id} currentLocale={currentUser.current_locale} 
            status={journey.status} statusReason={journey.status_reason} {...{update, index}}/>
          </div>
          
          <div className="m-b-10">
            {data.founder_name}
          </div>

          <div className="m-b-10">
            {ds?.health_condition?.label}
          </div>
        
          <div className="flex aligncenter m-b-10">
            <div className="flex aligncenter m-r-20">
              <PhoneIcon title={data.mobile_number || <font className="c-bemedian">Mobile Not Provided</font>}/>
            </div>
            <div className="flex aligncenter">
              <MapIcon title={data.district || <font className="c-bemedian">Address Not Provided</font>}/>
            </div>
          </div>

          <div className="flex aligncenter m-b-10">
            <div className="flex aligncenter m-r-20">
              {Cs.formatUpdateDate(journey.updated_at)}
            </div>
            <div className="flex aligncenter">
              {journey.last_updated_user?.name}
            </div>
          </div>
        </div>
        <div>
          {journey.status != 'closed' && 
            <div className="flex aligncenter justifyflexend">
              <LinkMapIcon id="path_details" tooltip="View path details" onSelect={()=>routeJourney(journey)}/>
              {journey.nutrition_care_alert_count > 0 &&
                <LinkMailIcon id="notification" count={journey.nutrition_care_alert_count} 
                  tooltip="Journey Notification" onSelect={()=>openNotification(journey)} 
                  className="far fa-envelope m-l-15 font-18 text-muted"/>
              }
              <LinkUserIcon tooltip="Assign journey" onSelect={()=>openUserRole(journey)}/>
              <LinkDeleteIcon id="delete_journey" tooltip="Delete journey" onSelect={()=>onDelete(journey)}/>
            </div>
          }
        </div>
      </div>
    </div>
  )  
}

export default NewVenture;