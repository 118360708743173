import React, {useState, useEffect, useRef} from "react";
import GenericModal from "../Modals/GenericModal";
import useModal from "../../hooks/useModal";
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView";
import TagMappingService from './TagMappingService';
import TagService from './TagService';
import { Spinner } from "../Common/Spinner";
import { useCurrentUserHook } from '../Authentication/useUserHook';
import { LinkFontAwesomeIcon, CheckBtn } from "../Common/ImageIcons";
import { NavIcon } from "../Common/MenuItem";

const TagForm = ({editTag, taggableType, onFormClose}) => {
  const {currentUser} = useCurrentUserHook();
  const orgId = currentUser.current_organization_id;

  const [tag, setTag] = useState(editTag || {});
  const [loading, setLoading] = useState(false);

  const crateOrUpdate = async () =>{
    setLoading(true);
    if(tag.id){
      const {status, data} = await TagService.update(tag);
      if(status === 204) onFormClose(tag);
    }else{
      const {status, data} = await TagService.create({
        'organization_id': orgId,
        'taggable_type': taggableType,
        ...tag
      });
      if(status === 201) onFormClose(data.tag);
    }
    setLoading(false);
  }

  const onInputChange = (name, value) =>{
    setTag({...tag, [name]:value});
  }

  return(
    <div className="ml-container">
      <div className="ml-section">
        <label className="form-label req-field">Title</label>
        <input onChange={e => onInputChange(e.target.name, e.target.value)} 
          defaultValue={tag.tag} className="form-control"
          type="text" placeholder="Tag Name" name="tag" required/>
      </div>

      <div className="flex rowrev">
        <div>
          {tag?.tag ?
            <button type="button" className="dropbtn bg-submit" onClick={()=>crateOrUpdate()}>
              Submit
            </button>
            : null
          }
          <button type="button" className="dropbtn bg-cancel" onClick={()=>onFormClose()}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}

const TagList = ({taggableType, taggableId, permission}) => {
  const {currentUser} = useCurrentUserHook();
  const orgId = currentUser.current_organization_id;

  let {current:scope} = useRef({});
  const [loading, setLoading] = useState(true);
  const [tags, setTags] = useState([]);
  const [tagMappings, setTagMappings] = useState([]);

  const {isOpen:isFormOpen, toggleModal:toggleForm} = useModal();

  useEffect(() => {
    getTags();
    getTagMappings();
  }, [])

  const getTags = async () =>{
    setLoading(true);
    const {status, data} = await TagService.getAll({
      'page':1,
      'per_page':100,
      'taggeable_type': taggableType,
      'organization_id': orgId
    });
    setTags(data.tags);
    setLoading(false);
  }

  const getTagMappings = async () =>{
    setLoading(true);
    const {status, data} = await TagMappingService.getAll({
      'page':1,
      'per_page':100,
      'organization_id': orgId,
      'taggable_id': taggableId,
      'taggable_type': taggableType
    });
    setTagMappings(data.tag_mappings);
    setLoading(false);
  }

  const remove = async (tag) =>{
    setLoading(true);
    const {status, data} = await TagService.delete(tag.id);
    if(status === 204){
      setTags(tags.filter(t => t.id !== tag.id));
    }
    setLoading(false);
  }

  const addOrRemoveMapping = async (tag) =>{
    setLoading(true);
    const mappingId = mappingExists(tag.id);
    if(mappingId){
      const {status, data} = await TagMappingService.delete(mappingId);
      if(status === 204){
        setTagMappings(tagMappings.filter(t => t.id !== mappingId));
      }
    }else{
      const {status, data} = await TagMappingService.create({
        'tag_id': tag.id,
        'organization_id': orgId,
        'taggable_id': taggableId,
        'taggable_type': taggableType 
      });
      if(status === 201){
        setTagMappings([data.tag_mapping, ...tagMappings]);
      }
    }
    setLoading(false);
  }

  const onFormClose = (tag) =>{
    if(tag){
      const tempList = tags.filter(t => t.id !== tag.id);
      setTags([tag, ...tempList]);
    }
    scope.selectedTag = null;
    toggleForm();
  }

  const mappingExists = (tagId) =>{
    return tagMappings.find(m => m.tag_id === tagId)?.id;
  }

  if(isFormOpen){ 
    return( 
      <TagForm editTag={scope.selectedTag}
      onFormClose={onFormClose}
      taggableType={taggableType}/>
    )
  }

  return(
    <div id="ct" className="row">
      {loading ? <Spinner/> : null}
      <div className="flex rowrev">
        {permission?.write ?
          <NavIcon id="add" dataTip="Add Tag" 
            className="bg-highlight round-btn m-t-5 m-r-10" 
            icon="fab fa-plus"
            onSelect={()=>toggleForm()}/>
          : null
        }
      </div>
      <Table className="shadow-small">
        <THead>
          <TR>
            <TH>
              Tag
            </TH>
            <TH>
              Options
            </TH>
          </TR>
        </THead>
        <TBody>
          {tags.map((t, k)=>
            <TR key={k}>
              <TD className="text-cap">{t.tag}</TD>
              <TD className="flex aligncenter">
                <CheckBtn css="m-l-5 m-r-5" tooltip="Select" 
                  onSelect={() => addOrRemoveMapping(t)} 
                  exists={() => mappingExists(t.id)}/>
                {permission?.write ?
                  <>
                    <LinkFontAwesomeIcon id="edit" tooltip="Edit" 
                    onSelect={()=>{scope.selectedTag = t;toggleForm()}}
                    parentCss="bg-lgrey" iconCss="fas fa-edit white"/>
                    
                    <LinkFontAwesomeIcon id="delete" tooltip="Delete" 
                    onSelect={()=>remove(t)} 
                    parentCss="bg-lgrey" iconCss="fas fa-trash white"/>
                  </>
                  : null
                }
              </TD>
            </TR>
          )}
        </TBody>
      </Table>
    </div>
  )
}

const TagIcon = (props) =>{
  const {isOpen:isTaggingOpen, toggleModal: toggleTaggingPopup} = useModal();

  if(isTaggingOpen){
    return( 
      <GenericModal component={TagList} title={props.title}
      isOpen={isTaggingOpen} toggleModal={toggleTaggingPopup}
      {...props}/>
    )
  }

  return(
    <LinkFontAwesomeIcon parentCss="m-l-10 bg-lgrey" 
      onSelect={()=>toggleTaggingPopup()}
      iconCss="white font-18 fas fa-user-tag"/>
  )
}

export {TagIcon, TagForm, TagList};